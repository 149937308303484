<template>
	<div class="main-body p-0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
				<div class="inner-main-body">
					<div class="card card-featured card-featured-primary">
						<div class="card-body">
							<div class="row">
								<div class="form-group col-md-3">
									<label>Motivo de requerimiento <span class="text-danger">*</span></label>
									<v-select
										v-model="requirement_reasons_selected"
										:options="requirements.requirement_reasons"
										label="name"
										placeholder="Seleccionar"
										name="requirement_reason_id"
										v-validate="'required'"
										data-vv-as="'Motivo de requerimiento'"
										@input="mtd_changueRequirementReason" />
									<span v-show="errors.has('requirement_reason_id')" class="form-group__error"
										><i class="fas fa-exclamation-circle"></i> {{ errors.first('requirement_reason_id') }}
									</span>
									<ErrorBackendComponent :errorsBackend="errorsBackend" name="requirement_reason_id" />
								</div>
								<div class="form-group col-md-4">
									<label>Sede <span class="text-danger">*</span></label>
									<v-select
										v-model="requirement_campu_selected"
										:options="requirements.campus"
										label="name"
										placeholder="Seleccionar"
										name="requirement_campu_id"
										v-validate="'required'"
										data-vv-as="'Sede'"
										@input="mtd_changueRequirementCampu" />
									<span v-show="errors.has('requirement_campu_id')" class="form-group__error"
										><i class="fas fa-exclamation-circle"></i> {{ errors.first('requirement_campu_id') }}
									</span>
									<ErrorBackendComponent :errorsBackend="errorsBackend" name="requirement_campu_id" />
								</div>
								<!-- PUESTO-->
								<div class="form-group col-md-4">
									<label>Puesto <span class="text-danger">*</span></label>
									<v-select
										v-model="requirement.job_position_id"
										:options="requirements.job_positions"
										:reduce="jp => jp.id"
										label="name"
										placeholder="Seleccionar"
										name="job_position_id"
										v-validate="'required'"
										data-vv-as="'Puesto'" />
									<span v-show="errors.has('job_position_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('job_position_id') }} </span>
									<ErrorBackendComponent :errorsBackend="errorsBackend" name="job_position_id" />
								</div>
								<!-- HORARIO-->
								<div class="form-group col-md-4">
									<label>Horario <span class="text-danger">*</span></label>
									<input v-model.trim="requirement.schedule" type="text" class="form-control form-control-sm" name="schedule" v-validate="'required'" data-vv-as="'Horario'" />
									<span v-show="errors.has('schedule')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('schedule') }}</span>
									<ErrorBackendComponent :errorsBackend="errorsBackend" name="schedule" />
								</div>
							</div>
							<template v-if="cp_requirementReasonSelectedIsNewPosition">
								<div class="row">
									<div class="col-md-7">
										<div class="row">
											<!-- MODALIDAD DE CONTRATO -->
											<div class="form-group col-md-4">
												<label>Modalidad de contrato <span class="text-danger">*</span></label>
												<select
													class="form-control form-control-sm"
													name="contract_id"
													v-validate="'required'"
													data-vv-as="'Modalidad de contrato'"
													v-model="requirement.contract_id">
													<option :value="null">Seleccionar</option>
													<option :value="item.id" v-for="(item, index) in requirements.contracts" :key="index">{{ item.name }}</option>
												</select>
												<span v-show="errors.has('contract_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('contract_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="contract_id" />
											</div>
											<!-- GENERO-->
											<div class="form-group col-md-4">
												<label>Género <span class="text-danger">*</span></label>
												<v-select
													v-model="requirement.gender_id"
													:options="requirements.genders"
													:reduce="cm => cm.id"
													label="name"
													placeholder="Seleccionar"
													name="gender_id"
													v-validate="'required'"
													data-vv-as="'Género'" />
												<span v-show="errors.has('gender_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('gender_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="gender_id" />
											</div>
											<!-- RANGO DE EDAD-->
											<div class="form-group col-md-4">
												<label>Rango de edad <span class="text-danger">*</span></label>
												<input
													v-model.trim="requirement.age_range"
													type="text"
													class="form-control form-control-sm"
													name="age_range"
													v-validate="'required'"
													data-vv-as="'Rango de edad'" />
												<span v-show="errors.has('age_range')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('age_range') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="age_range" />
											</div>
											<!-- NUMERO DE VACANTES-->
											<div class="form-group col-md-4">
												<label>Num. de vacantes <span class="text-danger">*</span></label>
												<input
													v-model="requirement.vacancies_number"
													type="text"
													class="form-control form-control-sm"
													name="vacancies_number"
													v-validate="'required'"
													data-vv-as="'Num. de vacantes'" />
												<span v-show="errors.has('vacancies_number')" class="form-group__error"
													><i class="fas fa-exclamation-circle"></i> {{ errors.first('vacancies_number') }}</span
												>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="vacancies_number" />
											</div>
											<!-- REMUNERACION -->
											<div class="form-group col-md-4" v-if="requirement.contract_id == 1">
												<label>Salario <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Salario'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
											<div class="form-group col-md-4" v-if="requirement.contract_id == 2">
												<label>Costo por hora <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Costo por hora'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
											<!-- JUSTIFICACION DEL REQUERIMIENTO -->
											<div class="form-group col-md-12">
												<label>Justificación del requerimiento <span class="text-danger">*</span></label>
												<textarea
													v-model="requirement.justification"
													class="form-control form-control-sm"
													rows="4"
													name="justification"
													v-validate="'required'"
													data-vv-as="'Justificación'"></textarea>
												<span v-show="errors.has('justification')" class="form-group__error"><i class="fas fa-exclamation-circle"></i>{{ errors.first('justification') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="justification" />
											</div>
										</div>
									</div>
									<div class="col-md-5">
										<!-- CARRITO DE BENEFICIOS -->
										<fieldset class="form-fieldset pb-0">
											<legend>Beneficioss <i @click="mtd_addBenefit" class="fas fa-plus-circle text-primary cursor-pointer"></i></legend>
											<div v-if="requirement.benefits.length" class="form-group col-md-12">
												<div class="row">
													<template v-for="(benefit, bi) in requirement.benefits">
														<div class="form-group col-md-6" :key="'type_' + bi">
															<select :name="bi + '_type'" v-model="benefit.benefit_type_id" class="form-control form-control-sm">
																<option :value="null">Seleccionar</option>
																<option :value="item.id" v-for="(item, index) in requirements.benefit_types" :key="index">{{ item.name }}</option>
															</select>
															<span v-show="errors.has(bi + '_type')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_type') }} </span>
														</div>
														<div class="form-group col-md-4" :key="'m_' + bi">
															<IMaskComponent
																@accept="mtd_changeAmount"
																:mask="Number"
																thousandsSeparator=","
																:unmask="true"
																radix="."
																:padFractionalZeros="true"
																class="form-control form-control-sm"
																:name="bi + '_amount'"
																v-model="benefit.amount"
																v-validate="'required'"
																data-vv-as="'Monto'"
																placeholder="Monto" />
															<span v-show="errors.has(bi + '_amount')" class="form-group__error">
																<i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_amount') }}
															</span>
														</div>
														<div :key="'delete_' + bi" class="form-group col-md-2 align-self-start d-flex justify-content-center">
															<button @click="mtd_removeBenefit(bi)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
														</div>
													</template>
												</div>
											</div>
											<div v-else>
												<p class="text-center text-muted">Click en el icono <i class="fas fa-plus-circle"></i> para añadir beneficios</p>
											</div>
										</fieldset>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6">
										<label>Funciones principales</label>
										<textarea v-model="requirement.principal_function" class="form-control form-control-sm" rows="4"></textarea>
									</div>
									<div class="form-group col-md-6">
										<label>Requisitos</label>
										<textarea v-model="requirement.requisites" class="form-control form-control-sm" rows="4"></textarea>
									</div>
								</div>
							</template>
							<template v-if="cp_requirementReasonSelectedIsLicenseOrReplacement">
								<div class="row">
									<div class="col-md-7">
										<div class="row">
											<!-- MODALIDAD DE CONTRATO -->
											<div class="form-group col-md-4">
												<label>Modalidad de contrato <span class="text-danger">*</span></label>
												<select
													class="form-control form-control-sm"
													name="contract_id"
													v-validate="'required'"
													data-vv-as="'Modalidad de contrato'"
													v-model="requirement.contract_id">
													<option :value="null">Seleccionar</option>
													<option :value="item.id" v-for="(item, index) in requirements.contracts" :key="index">{{ item.name }}</option>
												</select>
												<span v-show="errors.has('contract_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('contract_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="contract_id" />
											</div>
											<!-- REEMPLAZA A -->
											<div class="form-group col-md-8">
												<label>Reemplaza a <span class="text-danger">*</span></label>
												<input
													v-model="requirement.replaces_to"
													type="text"
													class="form-control form-control-sm"
													name="replaces_to"
													v-validate="'required'"
													data-vv-as="'Reemplaza a'" />
												<span v-show="errors.has('replaces_to')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('replaces_to') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="replaces_to" />
											</div>
											<!-- GENERO-->
											<div class="form-group col-md-4">
												<label>Género <span class="text-danger">*</span></label>
												<v-select
													v-model="requirement.gender_id"
													:options="requirements.genders"
													:reduce="cm => cm.id"
													label="name"
													placeholder="Seleccionar"
													name="gender_id"
													v-validate="'required'"
													data-vv-as="'Género'" />
												<span v-show="errors.has('gender_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('gender_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="gender_id" />
											</div>
											<!-- RANGO DE EDAD-->
											<div class="form-group col-md-4">
												<label>Rango de edad <span class="text-danger">*</span></label>
												<input
													v-model.trim="requirement.age_range"
													type="text"
													class="form-control form-control-sm"
													name="age_range"
													v-validate="'required'"
													data-vv-as="'Rango de edad'" />
												<span v-show="errors.has('age_range')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('age_range') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="age_range" />
											</div>
											<!-- REMUNERACION -->
											<div class="form-group col-md-4" v-if="requirement.contract_id == 1">
												<label>Salario <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Salario'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
											<div class="form-group col-md-4" v-if="requirement.contract_id == 2">
												<label>Costo por hora <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Costo por hora'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
										</div>
									</div>
									<div class="col-md-5">
										<fieldset class="form-fieldset pb-0">
											<legend>Beneficioss <i @click="mtd_addBenefit" class="fas fa-plus-circle text-primary cursor-pointer"></i></legend>
											<div v-if="requirement.benefits.length" class="form-group col-md-12">
												<div class="row">
													<template v-for="(benefit, bi) in requirement.benefits">
														<div class="form-group col-md-6" :key="'type_' + bi">
															<select :name="bi + '_type'" v-model="benefit.benefit_type_id" class="form-control form-control-sm">
																<option :value="null">Seleccionar</option>
																<option :value="item.id" v-for="(item, index) in requirements.benefit_types" :key="index">{{ item.name }}</option>
															</select>
															<span v-show="errors.has(bi + '_type')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_type') }} </span>
														</div>
														<div class="form-group col-md-4" :key="'m_' + bi">
															<IMaskComponent
																@accept="mtd_changeAmount"
																:mask="Number"
																thousandsSeparator=","
																:unmask="true"
																radix="."
																:padFractionalZeros="true"
																class="form-control form-control-sm"
																:name="bi + '_amount'"
																v-model="benefit.amount"
																v-validate="'required'"
																data-vv-as="'Monto'"
																placeholder="Monto" />
															<span v-show="errors.has(bi + '_amount')" class="form-group__error">
																<i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_amount') }}
															</span>
														</div>
														<div :key="'delete_' + bi" class="form-group col-md-2 align-self-start d-flex justify-content-center">
															<button @click="mtd_removeBenefit(bi)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
														</div>
													</template>
												</div>
											</div>
											<div v-else>
												<p class="text-center text-muted">Click en el icono <i class="fas fa-plus-circle"></i> para añadir beneficios</p>
											</div>
										</fieldset>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6">
										<label>Funciones principales</label>
										<textarea v-model="requirement.principal_function" class="form-control form-control-sm" rows="4"></textarea>
									</div>
									<div class="form-group col-md-6">
										<label>Requisitos</label>
										<textarea v-model="requirement.requisites" class="form-control form-control-sm" rows="4"></textarea>
									</div>
								</div>
							</template>
							<template v-if="cp_requirementReasonSelectedIsCessation">
								<div class="row">
									<div class="col-md-7">
										<div class="row">
											<!-- MODALIDAD DE CONTRATO -->
											<div class="form-group col-md-4">
												<label>Modalidad de contrato <span class="text-danger">*</span></label>
												<select
													class="form-control form-control-sm"
													name="contract_id"
													v-validate="'required'"
													data-vv-as="'Modalidad de contrato'"
													v-model="requirement.contract_id">
													<option :value="null">Seleccionar</option>
													<option :value="item.id" v-for="(item, index) in requirements.contracts" :key="index">{{ item.name }}</option>
												</select>
												<span v-show="errors.has('contract_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('contract_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="contract_id" />
											</div>
											<!-- REEMPLAZA A -->
											<div class="form-group col-md-8">
												<label>Reemplaza a <span class="text-danger">*</span></label>
												<input
													v-model="requirement.replaces_to"
													type="text"
													class="form-control form-control-sm"
													name="replaces_to"
													v-validate="'required'"
													data-vv-as="'Reemplaza a'" />
												<span v-show="errors.has('replaces_to')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('replaces_to') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="replaces_to" />
											</div>
											<!-- GENERO-->
											<div class="form-group col-md-4">
												<label>Género <span class="text-danger">*</span></label>
												<v-select
													v-model="requirement.gender_id"
													:options="requirements.genders"
													:reduce="cm => cm.id"
													label="name"
													placeholder="Seleccionar"
													name="gender_id"
													v-validate="'required'"
													data-vv-as="'Género'" />
												<span v-show="errors.has('gender_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('gender_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="gender_id" />
											</div>
											<!-- FECHA DE CESE -->
											<div class="form-group col-md-4">
												<label>Fecha de cese <span class="text-danger">*</span></label>
												<flat-pickr
													v-model="requirement.cessation_date"
													:config="{
														minDate: new Date(),
													}"
													class="form-control form-control-sm flatpickr-input"
													placeholder="Seleccionar"
													name="cessation_date"
													v-validate="'required'"
													data-vv-as="'Fecha de cese'"></flat-pickr>
												<span v-show="errors.has('cessation_date')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('cessation_date') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="cessation_date" />
											</div>
											<!-- REMUNERACION -->
											<div class="form-group col-md-4" v-if="requirement.contract_id == 1">
												<label>Salario <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Salario'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
											<div class="form-group col-md-4" v-if="requirement.contract_id == 2">
												<label>Costo por hora <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Costo por hora'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
										</div>
									</div>
									<div class="col-md-5">
										<!-- CARRITO DE BENEFICIOS -->
										<fieldset class="form-fieldset pb-0">
											<legend>Beneficioss <i @click="mtd_addBenefit" class="fas fa-plus-circle text-primary cursor-pointer"></i></legend>
											<div v-if="requirement.benefits.length" class="form-group col-md-12">
												<div class="row">
													<template v-for="(benefit, bi) in requirement.benefits">
														<div class="form-group col-md-6" :key="'type_' + bi">
															<select :name="bi + '_type'" v-model="benefit.benefit_type_id" class="form-control form-control-sm">
																<option :value="null">Seleccionar</option>
																<option :value="item.id" v-for="(item, index) in requirements.benefit_types" :key="index">{{ item.name }}</option>
															</select>
															<span v-show="errors.has(bi + '_type')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_type') }} </span>
														</div>
														<div class="form-group col-md-4" :key="'m_' + bi">
															<IMaskComponent
																@accept="mtd_changeAmount"
																:mask="Number"
																thousandsSeparator=","
																:unmask="true"
																radix="."
																:padFractionalZeros="true"
																class="form-control form-control-sm"
																:name="bi + '_amount'"
																v-model="benefit.amount"
																v-validate="'required'"
																data-vv-as="'Monto'"
																placeholder="Monto" />
															<span v-show="errors.has(bi + '_amount')" class="form-group__error">
																<i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_amount') }}
															</span>
														</div>
														<div :key="'delete_' + bi" class="form-group col-md-2 align-self-start d-flex justify-content-center">
															<button @click="mtd_removeBenefit(bi)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
														</div>
													</template>
												</div>
											</div>
											<div v-else>
												<p class="text-center text-muted">Click en el icono <i class="fas fa-plus-circle"></i> para añadir beneficios</p>
											</div>
										</fieldset>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6">
										<label>Funciones principales</label>
										<textarea v-model="requirement.principal_function" class="form-control form-control-sm" rows="4"></textarea>
									</div>
									<div class="form-group col-md-6">
										<label>Requisitos</label>
										<textarea v-model="requirement.requisites" class="form-control form-control-sm" rows="4"></textarea>
									</div>
								</div>
							</template>
							<template v-if="cp_requirementReasonSelectedIsOthers">
								<div class="row">
									<div class="col-md-7">
										<div class="row">
											<!-- MODALIDAD DE CONTRATO -->
											<div class="form-group col-md-4">
												<label>Modalidad de contrato <span class="text-danger">*</span></label>
												<select
													class="form-control form-control-sm"
													name="contract_id"
													v-validate="'required'"
													data-vv-as="'Modalidad de contrato'"
													v-model="requirement.contract_id">
													<option :value="null">Seleccionar</option>
													<option :value="item.id" v-for="(item, index) in requirements.contracts" :key="index">{{ item.name }}</option>
												</select>
												<span v-show="errors.has('contract_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('contract_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="contract_id" />
											</div>
											<!-- GENERO-->
											<div class="form-group col-md-4">
												<label>Género <span class="text-danger">*</span></label>
												<v-select
													v-model="requirement.gender_id"
													:options="requirements.genders"
													:reduce="cm => cm.id"
													label="name"
													placeholder="Seleccionar"
													name="gender_id"
													v-validate="'required'"
													data-vv-as="'Género'" />
												<span v-show="errors.has('gender_id')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('gender_id') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="gender_id" />
											</div>
											<!-- RANGO DE EDAD-->
											<div class="form-group col-md-4">
												<label>Rango de edad <span class="text-danger">*</span></label>
												<input
													v-model.trim="requirement.age_range"
													type="text"
													class="form-control form-control-sm"
													name="age_range"
													v-validate="'required'"
													data-vv-as="'Género'" />
												<span v-show="errors.has('age_range')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('age_range') }} </span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="age_range" />
											</div>
											<!-- NUMERO DE VACANTES-->
											<div class="form-group col-md-4">
												<label>Num. de vacantes <span class="text-danger">*</span></label>
												<input
													v-model="requirement.vacancies_number"
													type="text"
													class="form-control form-control-sm"
													name="vacancies_number"
													v-validate="'required'"
													data-vv-as="'Num. de vacantes'" />
												<span v-show="errors.has('vacancies_number')" class="form-group__error"
													><i class="fas fa-exclamation-circle"></i> {{ errors.first('vacancies_number') }}
												</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="vacancies_number" />
											</div>
											<!-- REMUNERACION -->
											<div class="form-group col-md-4" v-if="requirement.contract_id == 1">
												<label>Salario <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Salario'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
											<div class="form-group col-md-4" v-if="requirement.contract_id == 2">
												<label>Costo por hora <span class="text-danger">*</span></label>
												<IMaskComponent
													v-model="requirement.remuneration"
													:unmask="true"
													class="form-control form-control-sm"
													name="remuneration"
													v-validate="'required'"
													data-vv-as="'Costo por hora'" />
												<span v-show="errors.has('remuneration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('remuneration') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="remuneration" />
											</div>
											<!-- JUSTIFICACION DEL REQUERIMIENTO -->
											<div class="form-group col-md-12">
												<label>Justificación del requerimiento <span class="text-danger">*</span></label>
												<textarea
													v-model="requirement.justification"
													class="form-control form-control-sm"
													rows="4"
													name="justification"
													v-validate="'required'"
													data-vv-as="'Justificación'"></textarea>
												<span v-show="errors.has('justification')" class="form-group__error"><i class="fas fa-exclamation-circle"></i>{{ errors.first('justification') }}</span>
												<ErrorBackendComponent :errorsBackend="errorsBackend" name="justification" />
											</div>
										</div>
									</div>
									<div class="col-md-5">
										<!-- CARRITO DE BENEFICIOS -->
										<fieldset class="form-fieldset pb-0">
											<legend>Beneficioss <i @click="mtd_addBenefit" class="fas fa-plus-circle text-primary cursor-pointer"></i></legend>
											<div v-if="requirement.benefits.length" class="form-group col-md-12">
												<div class="row">
													<template v-for="(benefit, bi) in requirement.benefits">
														<div class="form-group col-md-6" :key="'type_' + bi">
															<select :name="bi + '_type'" v-model="benefit.benefit_type_id" class="form-control form-control-sm">
																<option :value="null">Seleccionar</option>
																<option :value="item.id" v-for="(item, index) in requirements.benefit_types" :key="index">{{ item.name }}</option>
															</select>
															<span v-show="errors.has(bi + '_type')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_type') }} </span>
														</div>
														<div class="form-group col-md-4" :key="'m_' + bi">
															<IMaskComponent
																@accept="mtd_changeAmount"
																:mask="Number"
																thousandsSeparator=","
																:unmask="true"
																radix="."
																:padFractionalZeros="true"
																class="form-control form-control-sm"
																:name="bi + '_amount'"
																v-model="benefit.amount"
																v-validate="'required'"
																data-vv-as="'Monto'"
																placeholder="Monto" />
															<span v-show="errors.has(bi + '_amount')" class="form-group__error">
																<i class="fas fa-exclamation-circle"></i> {{ errors.first(bi + '_amount') }}
															</span>
														</div>
														<div :key="'delete_' + bi" class="form-group col-md-2 align-self-start d-flex justify-content-center">
															<button @click="mtd_removeBenefit(bi)" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
														</div>
													</template>
												</div>
											</div>
											<div v-else>
												<p class="text-center text-muted">Click en el icono <i class="fas fa-plus-circle"></i> para añadir beneficios</p>
											</div>
										</fieldset>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6">
										<label>Funciones principales</label>
										<textarea v-model="requirement.principal_function" class="form-control form-control-sm" rows="4"></textarea>
									</div>
									<div class="form-group col-md-6">
										<label>Requisitos</label>
										<textarea v-model="requirement.requisites" class="form-control form-control-sm" rows="4"></textarea>
									</div>
								</div>
							</template>
							<!-- BOTON DE REGISTRAR -->
							<div class="row">
								<div class="col-md-12 text-center">
									<button @click="mtd_send('this.mtd_store()')" class="btn btn-sm btn-primary" :disabled="cp_disabledButtonSubmit">Registrar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'main-form'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Benefit from './components/Benefit';

export default {
	components: {
		Benefit,
	},
	data() {
		return {
			//Data primaria
			requirements: {
				job_positions: [],
				contracts: [],
				requirement_reasons: [],
				genders: [],
				benefit_types: [],
				campus: [],
			},
			requirement: {
				job_position_id: null,
				requirement_reason_id: null,
				schedule: '',
				contract_id: null,
				gender_id: null,
				age_range: null,
				remuneration: null,
				justification: null,
				principal_function: null,
				vacancies_number: null,
				replaces_to: null,
				cessation_date: null,
				requisites: null,
				benefits: [],
			},
			requirement_reasons_selected: null,
			requirement_campu_selected: null,

			//Data secundaria
			errorsBackend: {},
			dataModel: {
				alias: 'Mis requerimientos',
				icon: 'fas fa-book-reader',
			},
			page: {
				header: {
					menu: ['Nuevo'],
				},
				buttons: {
					create: false,
					import: false,
					export: false,
				},
			},
			modals: [
				{
					id: 'main-form',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'main-form',
						idHash: '#main-form',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters(['url_api']),
		cp_disabledButtonSubmit() {
			return this.errors.any();
		},
		cp_requirementReasonSelectedIsNewPosition() {
			return this.requirement_reasons_selected != null && this.requirement_reasons_selected.code == 'NEW_POSITION';
		},
		cp_requirementReasonSelectedIsLicenseOrReplacement() {
			return this.requirement_reasons_selected != null && this.requirement_reasons_selected.code == 'LICENSE_OR_REPLACEMENT';
		},
		cp_requirementReasonSelectedIsCessation() {
			return this.requirement_reasons_selected != null && this.requirement_reasons_selected.code == 'CESSATION';
		},
		cp_requirementReasonSelectedIsOthers() {
			return this.requirement_reasons_selected != null && this.requirement_reasons_selected.code == 'OTHERS';
		},
	},
	mounted() {
		this.mtd_createRequirement();
	},
	methods: {
		...mapActions(['get', 'post', 'destroy', 'execute_commit', 'update']),
		mtd_changeAmount(amount_base) {
			const amount = parseFloat(amount_base).toFixed(2);
			this.$emit('update:amount', amount);
		},
		mtd_createRequirement() {
			this.get({ url: `${this.url_api}/requirement/create` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.contracts = res.contracts;
						this.requirements.requirement_reasons = res.requirement_reasons;
						this.requirements.genders = res.genders;
						this.requirements.benefit_types = res.benefit_types;
						this.requirements.campus = res.campus;
					}
				})
				.catch(err => console.log(err));
		},

		mtd_changueRequirementReason(requirement_reason) {
			if (requirement_reason != null) {
				this.requirement.requirement_reason_id = requirement_reason.id;
			}
		},
		mtd_changueRequirementCampu(campu) {
			this.requirement.job_position_id = null;
			if (campu != null) {
				this.requirements.job_positions = campu.job_positions;
			} else {
				this.requirements.job_positions = [];
			}
		},
		mtd_store() {
			this.$swal
				.fire({
					icon: 'warning',
					title: '¿Está seguro de registrar el requerimiento?',
					showCancelButton: true,
					confirmButtonText: 'Si, registrar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/requirement/store`, params: { requirement: this.requirement } }).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: 'Requerimiento registrado con éxito',
									text: `Código: ${res.requirement.requirement_number}`,
									showCancelButton: false,
								});
								this.$router.push({ name: 'administrator-requirements-index' });
							}
						});
					}
				});
		},
		mtd_addBenefit() {
			const benefit = {
				//benefit_types: this.requirements.benefit_types,
				benefit_type_id: null,
				amount: null,
			};
			this.requirement.benefits.push(benefit);
		},
		mtd_removeBenefit(benefit_pos) {
			this.requirement.benefits.splice(benefit_pos, 1);
		},

		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentSuccess: function ({ component, res, action, modal_id }) {
			switch (component) {
				case 'main-form':
					if (action == 'store') {
						this.execute_commit({ mutation: 'USERS_INDEX__ADD_RESOURCE', payload: { user: res.user } });
					}
					if (action == 'update') {
						this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
					}
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_send: function (method) {
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) _this.$eval(method);
			});
		},
	},
	beforeDestroy() {
		this.execute_commit({ mutation: 'USERS_INDEX__CLEAR_ALL' });
	},
};
</script>
