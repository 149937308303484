<template>
	<div class="row">
		<div class="form-group col-md-6">
			<v-select
				:options="benefit_types"
				@input="mtd_changueBenefitType"
				label="name"
				placeholder="Seleccionar"
				name="benefit_type_id"
				v-validate="'required'"
				data-vv-as="'Beneficio'" />
			<span v-show="errors.has('benefit_type_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('benefit_type_id') }} </span>
			<ErrorBackendComponent :errorsBackend="errorsBackend" name="benefit_type_id" />
		</div>
		<div class="form-group col-md-4">
			<IMaskComponent
				@accept="mtd_changeAmount"
				:mask="Number"
				thousandsSeparator=","
				:unmask="true"
				radix="."
				:padFractionalZeros="true"
				class="form-control form-control-sm"
				name="amount"
				v-validate="'required'"
				data-vv-as="'Monto'"
				placeholder="Monto" />
			<span v-show="errors.has('amount')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('amount') }} </span>
			<ErrorBackendComponent :errorsBackend="errorsBackend" name="amount" />
		</div>
		<div class="form-group col-md-2 align-self-start d-flex justify-content-center">
			<button @click="mtd_onRemove" class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		benefit_types: {
			required: true,
		},
		benefit_type_id: {
			required: true,
		},
		amount: {
			required: true,
		},
	},
	data() {
		return {
			errorsBackend: {},
		};
	},
	methods: {
		mtd_changeAmount(amount_base) {
			const amount = parseFloat(amount_base).toFixed(2);
			this.$emit('update:amount', amount);
		},
		mtd_changueBenefitType(benefit_type) {
			if (benefit_type != null) {
				this.$emit('update:benefit_type_id', benefit_type.id);
			} else {
				this.$emit('update:benefit_type_id', null);
			}
		},
		mtd_onRemove() {
			this.$emit('on-remove');
		},
		mtd_changeScheduledReceivingDate(scheduled_receiving_date_base) {
			const scheduled_receiving_date = this.getFormatedDate(new Date(scheduled_receiving_date_base));
			this.$emit('update:scheduled_receiving_date', scheduled_receiving_date);
		},
	},
};
</script>
<style></style>
